import React from 'react';

import Plans from '../contents/plans.json';
import Layout from '../components/Layout';

import * as styles from './pricing.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import TableView from '../components/TableView';
import CTA from '../components/CTA';
import { useSiteMetadata } from '../hooks';
import Container from '../components/Container';
import { HeadFC } from 'gatsby';
import Seo from '../components/Seo';

interface Plan {
  name: string;
  description: string;
  plans: { [key: string]: string }[];
}

const columns = [
  {
    key: 'label',
    name: 'Plans',
  },
  {
    key: 'nodes',
    name: 'Number of Nodes',
  },
  {
    key: 'price_monthly',
    name: 'Monthly',
  },
  {
    key: 'price_hourly',
    name: 'Hourly',
  },
  {
    key: 'memory',
    name: 'RAM',
  },
  {
    key: 'vcpus',
    name: 'CPU',
  },
  {
    key: 'disk',
    name: 'Storage',
  },
  {
    key: 'network',
    name: 'Network In/Out',
  },
];

const Pricing = () => {
  const data = useSiteMetadata();

  return (
    <Layout>
      <section>
        <Container className={styles.theme}>
          <div className={styles.container}>
            <h2 className={styles.mainTitle}>Bitnimbus.io Platform Pricing</h2>
            <h3 className={styles.caption}>
              The most affordable enterprise-grade data streaming platform
            </h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CTA
                type="primary"
                link="/#contact-us"
                external={false}
                label="Get in Touch"
              />
            </div>
          </div>
          <div className={styles.planContainer}>
            <h2 className={styles.title}>Find the Right Plan for You</h2>
            <div className={styles.providerContainer}>
              <h5 className={styles.providerTitle}>Cloud Provider</h5>
              <div className={styles.providerOptions}>
                <input type="radio" value="akamai" checked readOnly />
                <StaticImage
                  className={styles.image}
                  src="../images/akamai.png"
                  alt="akamai-logo"
                  imgStyle={{ width: 'auto', height: '100%' }}
                />
                <p>Akamai Cloud</p>
              </div>
            </div>
            <div className={styles.planDetails}>
              <h4 className={styles.planTitle}>Small Plan</h4>
              <p className={styles.planDescription}>
                The small plan offers a great starting point for small
                organizations or hobby developers to develop products with
                Kafka.
              </p>
              <TableView
                columns={columns}
                data={Plans.filter((p) => p.group === 'Small')}
              />
            </div>
            <div className={styles.planDetails}>
              <h4 className={styles.planTitle}>Medium Plan</h4>
              <p className={styles.planDescription}>
                The medium plan offers fault tolerance and high availability for
                production workloads.
              </p>
              <TableView
                columns={columns}
                data={Plans.filter((p) => p.group === 'Medium')}
              />
            </div>
            <div className={styles.planDetails}>
              <h4 className={styles.planTitle}>Large Plan</h4>
              <p className={styles.planDescription}>
                The large plan offers fault tolerance and high availability for
                enterprise grade workloads.
              </p>
              <TableView
                columns={columns}
                data={Plans.filter((p) => p.group === 'Large')}
              />
            </div>
            <div className={styles.ctaContainer}>
              <h1>Spin Up Kafka Instances In Minutes</h1>
              <CTA
                label="Start Your free trial"
                external={true}
                link={data.site.siteMetadata.productLink}
                type="primary"
              />
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <>
    <Seo
      title="Pricing - Bitnimbus.io"
      canonical="https://www.bitnimbus.io/pricing"
      description="Discover affordable, fully managed Kafka clusters that fit your needs! Starting at $14/month with no credit card required. Sign up now and optimize your workflow!"
    />
  </>
);

export default Pricing;
