import React from 'react';

import * as styles from './syles.module.scss';
import Container from '../Container';

interface Column {
  key: string;
  name: string;
}

interface TableViewProps {
  columns: Column[];
  data: { [key: string]: string }[];
}

const TableView = (props: TableViewProps) => {
  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <Container className={`${styles.theme}`}>
        <div className={styles.container}>
          <table className={styles.table}>
            <thead>
              <tr>
                {props.columns.map((col) => (
                  <th key={col.key}>{col.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.data.map((d) => (
                <tr key={d.label}>
                  {props.columns.map((col, index) => (
                    <td key={`${d.label}-${index}`}>{d[col.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};

export default TableView;
