// extracted by mini-css-extract-plugin
export var caption = "pricing-module--caption--fb695";
export var container = "pricing-module--container--1d813";
export var ctaContainer = "pricing-module--cta-container--176bf";
export var image = "pricing-module--image--a6603";
export var mainTitle = "pricing-module--main-title--6d7c2";
export var planContainer = "pricing-module--plan-container--6b673";
export var planDescription = "pricing-module--plan-description--677aa";
export var planDetails = "pricing-module--plan-details--281be";
export var planTitle = "pricing-module--plan-title--7e929";
export var providerContainer = "pricing-module--provider-container--28911";
export var providerOptions = "pricing-module--provider-options--170c6";
export var providerTitle = "pricing-module--provider-title--41ef2";
export var theme = "pricing-module--theme--5db09";
export var title = "pricing-module--title--1bd84";